exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-dla-firm-js": () => import("./../../../src/pages/dla-firm.js" /* webpackChunkName: "component---src-pages-dla-firm-js" */),
  "component---src-pages-dzieki-bardzo-conf-js": () => import("./../../../src/pages/dzieki-bardzo-conf.js" /* webpackChunkName: "component---src-pages-dzieki-bardzo-conf-js" */),
  "component---src-pages-dzieki-bardzo-js": () => import("./../../../src/pages/dzieki-bardzo.js" /* webpackChunkName: "component---src-pages-dzieki-bardzo-js" */),
  "component---src-pages-dzieki-js": () => import("./../../../src/pages/dzieki.js" /* webpackChunkName: "component---src-pages-dzieki-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konferencja-js": () => import("./../../../src/pages/konferencja.js" /* webpackChunkName: "component---src-pages-konferencja-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-oferta-052024-js": () => import("./../../../src/pages/oferta052024.js" /* webpackChunkName: "component---src-pages-oferta-052024-js" */),
  "component---src-pages-sklep-js": () => import("./../../../src/pages/sklep.js" /* webpackChunkName: "component---src-pages-sklep-js" */),
  "component---src-pages-thx-js": () => import("./../../../src/pages/thx.js" /* webpackChunkName: "component---src-pages-thx-js" */),
  "component---src-pages-wieczory-js": () => import("./../../../src/pages/wieczory.js" /* webpackChunkName: "component---src-pages-wieczory-js" */)
}

