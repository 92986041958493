import './src/styles/global.css';
import keyboardOnlyOutlines from '@moxy/keyboard-only-outlines'

keyboardOnlyOutlines()

console.log(`%c                                    
  █████████
    ██████████
    ██████████
    ██████████
  █████████ █                                 
`, 'font-size: 20px;  color: #EE4A34;')